











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class SvgGen extends Vue {
  @Prop({ required: true }) color: string
  @Prop({ required: true }) path: string
}
