







import { Component, Prop, Vue } from 'vue-property-decorator'
import SvgGen from '@/components/shared/SvgGen.vue'
import { Endpoint } from '@/lib/api_repository'

@Component({ components: { SvgGen } })
export default class ApiName extends Vue {
  @Prop({ required: true }) api: Endpoint

  get iconClass (): string {
    return `fa-${this.api.icon}`
  }
}
