











import { Component, Vue, Prop } from 'vue-property-decorator'
import TypeTooltip from '@/components/graphql/shared/TypeTooltip.vue'
import { Field } from '@/types/graphql'
import { v4 as uuidv4 } from 'uuid'
import { routeTo } from '@/lib/routeBuilder'
import { typeValue } from '@/lib/typeConverter'

@Component({ components: { TypeTooltip } })
export default class FieldName extends Vue {
  @Prop({ required: true }) field!: Field

  get name (): string {
    let append = ''
    if (this.field.type.name === 'collection') append = ' [ ]'
    else if (this.field.type.name === 'input') append = ' { }'
    return this.field.name + append
  }

  get routeTo () {
    return routeTo(this.$route.params.api, this.field)
  }

  get typeValue (): string {
    return typeValue(this.field)
  }

  get uuid () {
    return uuidv4()
  }
}
