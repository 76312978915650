// NOTE: using loader syntax becuase Yaml worker imports editor.worker directly and that
// import shouldn't go through loader syntax.
// @ts-ignore
import EditorWorker from 'worker-loader!monaco-editor/esm/vs/editor/editor.worker'
// @ts-ignore
import JSONWorker from 'worker-loader!monaco-editor/esm/vs/language/json/json.worker'
// @ts-ignore
import GraphQLWorker from 'worker-loader!monaco-graphql/esm/graphql.worker'

// @ts-ignore
window.MonacoEnvironment = {
  getWorker (_workerId: string, label: string) {
    if (label === 'graphqlDev') {
      return new GraphQLWorker()
    }
    if (label === 'json') {
      return new JSONWorker()
    }
    return new EditorWorker()
  }
}
