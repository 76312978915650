







import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import repository from '@/lib/api_repository.ts'
import { Gray, LightGray, Orange } from '@/lib/theme'
declare let Redoc: any

@Component
export default class RedocView extends Vue {
  @Prop({ required: true }) api: string
  loading = true

  mounted () {
    this.load()
  }

  @Watch('api')
  onApiChange () {
    this.load()
  }

  load () {
    this.loading = true
    Redoc.init(repository.swagger(this.api), {
      scrollYOffset: 50,
      hideDownloadButton: true,
      hideLoading: true,
      theme: {
        colors: {
          text: {
            primary: Gray
          }
        },
        menu: {
          backgroundColor: LightGray,
          textColor: Gray,
          width: '25%'
        },
        logo: {
          maxHeight: 'initial',
          maxWidth: 'initial'
        },
        typography: {
          fontFamily: 'Open Sans, sans-serif',
          headings: {
            fontFamily: 'Open Sans, sans-serif'
          },
          links: {
            color: Orange
          }
        }
      }
    }, this.$refs.redoc, this.onLoad)
  }

  private onLoad (): void {
    this.loading = false
  }
}
