import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'
import GraphQLAPI from '../components/graphql/Api.vue'
import RestAPI from '../components/Redoc.vue'
import GraphQLPage from '../components/graphql/Page.vue'
import GraphQLRootField from '../components/graphql/RootField.vue'
import GraphQLEnum from '../components/graphql/Enum.vue'
import GraphQLType from '../components/graphql/Type.vue'

Vue.use(VueRouter)

const gqlObjectRoute = (typeName, component) => {
  return {
    path: `${typeName}/:name`,
    name: typeName,
    props: route => ({ ...route.params, type: typeName }),
    component
  }
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    children: [
      {
        path: 'rest/:api',
        name: 'restAPI',
        component: RestAPI,
        props: true
      },
      {
        path: 'graphql/:api',
        name: 'graphqlApi',
        component: GraphQLAPI,
        props: true,
        children: [
          gqlObjectRoute('page', GraphQLPage),
          gqlObjectRoute('query', GraphQLRootField),
          gqlObjectRoute('mutation', GraphQLRootField),
          gqlObjectRoute('enum', GraphQLEnum),
          gqlObjectRoute('type', GraphQLType),
          gqlObjectRoute('scalar', GraphQLType)
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
