import {
  GraphQLInputObjectType,
  GraphQLObjectType,
  GraphQLScalarType,
  GraphQLNonNull,
  GraphQLUnionType,
  GraphQLList,
  GraphQLEnumType,
  GraphQLOutputType,
  GraphQLField,
  GraphQLInputType
} from 'graphql'
import { Arg, Field, FieldType, GQLUnionType } from '@/types/graphql'
import { fetchFields } from '@/lib/typeConverter'

export class GraphQLReadableReturnType {
  entrypoint: GraphQLOutputType | GraphQLInputType

  constructor (entrypoint: GraphQLOutputType | GraphQLInputType) {
    this.entrypoint = entrypoint
  }

  get output (): FieldType {
    return this.traverse(this.entrypoint)
  }

  traverse (
    type: GraphQLOutputType | GraphQLInputType | GraphQLObjectType | GraphQLField<any, any> |
      FieldType | GraphQLScalarType | GraphQLNonNull<GraphQLObjectType> | GQLUnionType |
      GraphQLList<GraphQLObjectType> | GraphQLEnumType,
    nullable = true
  ): FieldType {
    switch (type.constructor) {
      case GraphQLInputObjectType:
        return this.GraphQLInputObjectType(type as FieldType, nullable)
      case GraphQLObjectType:
        return this.GraphQLObjectType(type as FieldType, nullable)
      case GraphQLScalarType:
        return this.GraphQLScalarType(type as GraphQLScalarType, nullable)
      case GraphQLNonNull:
        return this.GraphQLNonNull(type as GraphQLNonNull<GraphQLObjectType>)
      case GraphQLUnionType:
        return this.GraphQLUnionType(type as GQLUnionType, nullable)
      case GraphQLList:
        return this.GraphQLList(type as GraphQLList<GraphQLObjectType>, nullable)
      case GraphQLEnumType:
        return this.GraphQLEnumType(type as GraphQLEnumType, nullable)
    }
  }

  GraphQLInputObjectType (type: any, nullable: boolean) {
    return {
      name: 'input',
      value: type.name,
      description: type.description,
      nullable,
      args: fetchFields(type) as Arg[]
    }
  }

  // for fields
  GraphQLObjectType (type: FieldType, nullable: boolean) {
    return {
      name: 'type',
      value: type.name,
      description: type.description,
      nullable,
      fields: fetchFields(type) as Field[],
      _fields: type._fields
    }
  }

  GraphQLScalarType (type: GraphQLScalarType, nullable: boolean) {
    return {
      name: 'scalar',
      value: type.name,
      description: type.description,
      nullable
    }
  }

  GraphQLNonNull (type: GraphQLNonNull<GraphQLObjectType>) {
    return this.traverse(type.ofType, false)
  }

  GraphQLUnionType (type: GQLUnionType, nullable: boolean) {
    return {
      name: 'union',
      value: type.name,
      description: type.description,
      nullable,
      types: type._types.map(t => this.traverse(t))
    }
  }

  GraphQLList (type: GraphQLList<GraphQLObjectType>, nullable: boolean) {
    return {
      name: 'collection',
      value: this.traverse(type.ofType),
      description: this.traverse(type.ofType).description,
      nullable
    }
  }

  GraphQLEnumType (type: GraphQLEnumType, nullable: boolean) {
    return {
      name: 'enum',
      value: type.name,
      description: type.description,
      enums: type.getValues(),
      nullable
    }
  }
}
