












import { Component, Vue, Prop } from 'vue-property-decorator'
import TypeTooltip from '@/components/graphql/shared/TypeTooltip.vue'
import { Arg } from '@/types/graphql'
import { v4 as uuidv4 } from 'uuid'
import { routeTo } from '@/lib/routeBuilder'
import { typeValue } from '@/lib/typeConverter'

@Component({ components: { TypeTooltip } })
export default class FieldArg extends Vue {
  @Prop({ required: true }) arg!: Arg

  get hasDefaultValue (): boolean {
    return !!this.arg.defaultValue || [false, 0].includes(this.arg.defaultValue)
  }

  get routeTo () {
    return routeTo(this.$route.params.api, this.arg)
  }

  get typeValue (): string {
    return typeValue(this.arg)
  }

  get uuid () {
    return uuidv4()
  }
}
