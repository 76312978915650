import {
  getIntrospectionQuery,
  buildClientSchema,
  GraphQLSchema,
  TypeInfo,
  GraphQLEnumType,
  GraphQLScalarType,
  GraphQLObjectType,
  printSchema,
  GraphQLUnionType
} from 'graphql'
import axios from 'axios'

export class Api {
  endpoint: string
  schema: GraphQLSchema | null = null
  ready = false
  rawSchema = ''

  constructor (endpoint: string) {
    this.endpoint = endpoint
  }

  async fetchSchema () {
    if (this.schema !== null) return true
    const response = await this.rawQuery(getIntrospectionQuery())
    this.rawSchema = response.data.data
    this.schema = buildClientSchema(response.data.data)
    this.ready = true
  }

  async rawQuery (body: string) {
    return await axios({
      url: this.endpoint,
      method: 'post',
      data: {
        query: body
      }
    })
  }

  get typeInfo () {
    if (!this.schema) return
    return new TypeInfo(this.schema)
  }

  get queries () {
    return this.schema?.getQueryType()?.getFields() || {}
  }

  get mutations () {
    return this.schema?.getMutationType()?.getFields() || {}
  }

  get enums () {
    return this.types.filter(type => type instanceof GraphQLEnumType)
  }

  get scalars () {
    return this.types.filter(type => type instanceof GraphQLScalarType)
  }

  get objects () {
    return this.types.filter(
      type => (type instanceof GraphQLObjectType || type instanceof GraphQLUnionType) && !['Query', 'Mutation'].includes(type.name)
    )
  }

  get types () {
    return Object.values(this.schema?.getTypeMap() || []).filter(type => !type.name.startsWith('__'))
  }

  get printSchema () {
    if (!this.schema) return ''

    return printSchema(this.schema)
  }

  getQuery (name: string) {
    return this.schema?.getQueryType().getFields()[name]
  }

  getMutation (name: string) {
    return this.schema?.getMutationType().getFields()[name]
  }

  getType (name: string) {
    return this.schema?.getTypeMap()[name]
  }
}
