/* eslint-disable no-unused-vars */
import { Api } from '@/lib/api'

export type Endpoint = {
  id: string
  display: string
  description?: string
  icon?: string
  svgPath?: string
  swagger?: string
  schema?: Api
  route?: any
  children?: Endpoint[]
}

export const GRAPHQL_URL = process.env.VUE_APP_GRAPHQL_URL

class Apis {
  endpoints: Endpoint[] = [
    {
      id: 'kmsat',
      svgPath: 'M 16.00,0.68 C 22.22,-0.18 28.37,0.88 33.18,5.19 43.73,14.67 41.09,32.06 28.08,37.93 24.58,39.51 22.50,39.51 18.79,39.47 4.93,39.31 -3.35,24.36 1.82,12.07 4.39,5.96 9.64,2.10 16.00,0.68 Z M 28.02,28.85 C 28.02,28.85 29.03,29.99 30.40,31.11 33.34,27.88 35.76,23.58 35.42,19.04 34.84,11.38 29.01,4.84 21.11,4.65 16.23,4.53 11.96,5.94 8.72,9.76 5.70,13.34 5.61,17.30 5.61,17.30 5.61,17.30 3.72,17.22 2.77,17.30 2.77,17.30 6.66,23.86 6.66,23.86 11.82,17.47 10.44,20.06 11.82,17.65 11.82,17.65 9.04,17.65 9.04,17.65 11.06,5.27 28.73,4.92 31.55,17.18 32.04,19.32 31.75,22.14 30.88,24.15 30.04,26.06 28.02,28.85 28.02,28.85 Z',
      display: 'KMSAT',
      description: 'Navigate to our KMSAT API documentation here. KMSAT helps you train your users to understand the dangers of spam, phishing, spear phishing, malware, ransomware and social engineering through simulated phishing and security awareness training.',
      children: [
        {
          id: 'reporting',
          icon: 'file-invoice',
          display: 'Reporting',
          description: 'Our reporting APIs allow you to pull phishing, training, user, and group data from your KMSAT console.',
          swagger: '/elvis-swagger.yml',
          route: { name: 'restAPI', params: { api: 'reporting' } }
        },
        {
          id: 'userEvents',
          icon: 'users',
          display: 'User Events',
          description: "Our user event APIs allow you to push your users' security-related events or training activities from external sources and push them into/pull them from your KMSAT console.",
          swagger: '/echelon-swagger.yml',
          route: { name: 'restAPI', params: { api: 'userEvents' } }
        }
      ]
    },
    {
      id: 'phisher',
      svgPath: 'M 1.92,0.85 C 3.96,-0.42 3.43,0.42 19.79,0.10 30.73,0.52 36.25,-0.73 37.76,1.06 40.06,2.59 39.99,5.91 40.00,8.33 40.00,8.33 40.00,31.67 40.00,31.67 39.99,33.78 40.27,37.07 38.67,38.67 37.07,40.27 33.78,39.99 31.67,40.00 31.67,40.00 8.33,40.00 8.33,40.00 5.91,39.99 2.38,40.38 0.85,38.08 -0.10,36.65 0.01,34.17 0.00,32.50 0.00,32.50 0.00,8.33 0.00,8.33 0.01,4.92 -0.49,3.44 1.92,0.85 Z M 17.50,6.67 C 17.50,6.67 6.67,6.67 6.67,6.67 6.67,6.67 6.67,33.33 6.67,33.33 6.67,33.33 17.50,33.33 17.50,33.33 17.50,33.33 17.50,27.50 17.50,27.50 17.50,27.50 12.50,27.50 12.50,27.50 12.50,27.50 12.50,23.33 12.50,23.33 12.50,23.33 17.50,23.33 17.50,23.33 17.50,23.33 17.50,16.67 17.50,16.67 17.50,16.67 12.50,16.67 12.50,16.67 12.50,16.67 12.50,12.50 12.50,12.50 12.50,12.50 17.50,12.50 17.50,12.50 17.50,12.50 17.50,6.67 17.50,6.67 Z M 28.23,22.40 C 34.07,20.00 34.99,11.74 29.89,8.12 27.68,6.53 25.07,6.69 22.50,6.67 22.50,6.67 22.50,12.50 22.50,12.50 22.50,12.50 27.50,12.50 27.50,12.50 27.50,12.50 27.50,16.67 27.50,16.67 27.50,16.67 22.68,16.67 22.68,16.67 22.51,18.41 22.43,20.84 22.68,22.50 24.58,25.73 28.02,31.25 29.07,33.12 30.52,33.68 34.60,33.32 36.15,33.33 36.15,33.33 28.23,22.40 28.23,22.40 Z',
      display: 'PhishER',
      description: 'Navigate to our PhishER API documentation here. PhishER helps you evaluate all of the suspicious emails that make it through to your users’ inboxes. With PhishER, you can identify potential threats and strengthen your security measures and defense-in-depth plan.',
      schema: new Api(`${GRAPHQL_URL}?scope=phisher`),
      route: { name: 'graphqlApi', params: { api: 'phisher' } }
    }
  ]

  client (id: string): Api {
    return this.getEndpoint(id)?.schema
  }

  swagger (id: string) {
    return this.getEndpoint(id)?.swagger
  }

  getEndpoint (id: string, endpoints = this.endpoints): Endpoint | undefined {
    for (let endpoint of endpoints) {
      endpoint = endpoint.children ? this.getEndpoint(id, endpoint.children) : endpoint
      if (endpoint?.id === id) return endpoint
    }
  }
}

const apis = new Apis()
export default apis
