




import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import marked from 'marked'
import axios from 'axios'

@Component
export default class Page extends Vue {
  @Prop({ required: true }) name!: string
  @Prop({ required: true }) api!: string
  content = ''

  @Watch('$route', { immediate: true })
  async onRouteChanged () {
    const page = await axios.get(`/pages/${this.api}/${this.name}.md`)
    this.content = marked(page.data)
  }
}
