


























import { Component, Vue, Prop } from 'vue-property-decorator'
import { Arg, EnumType, Field, FieldType } from '@/types/graphql'
import FieldName from '@/components/graphql/shared/FieldName.vue'
import { routeTo } from '@/lib/routeBuilder'

@Component({ components: { FieldName } })
export default class TypeTooltip extends Vue {
  @Prop({ required: true }) target!: string
  @Prop({ required: true }) fieldType!: FieldType

  private uuids = {}

  get enums (): EnumType[] | null {
    return this.fieldType.enums?.length ? this.fieldType.enums : null
  }

  get hasContent (): boolean {
    return !!this.fieldType.description?.length || !!this.fieldType.args?.length ||
      !!this.fieldType.enums?.length
  }

  get isInput (): boolean {
    return this.fieldType.name === 'input'
  }

  get unwrapped () {
    if (this.fieldType.name === 'collection') return (this.fieldType.value as FieldType)
    return this.fieldType
  }

  routeTo (item: Field | Arg) {
    return routeTo(this.$route.params.api, item)
  }
}
