


































import { Component, Prop, Vue } from 'vue-property-decorator'
import GraphQLSidebar from '@/components/graphql/shared/Sidebar.vue'
import Graphiql from '@/components/graphql/shared/Graphiql.vue'
import apis from '@/lib/api_repository'
import { Field } from '@/types/graphql'

@Component({ components: { GraphQLSidebar, Graphiql } })
export default class GraphQLAPI extends Vue {
  @Prop({ required: true }) api!: string

  authVisible = true
  codeCols = 3
  loading = true
  private auth = ''
  private field: Field | null = null

  get authentication (): string {
    return this.auth || (this.auth = sessionStorage.getItem('papi'))
  }

  set authentication (auth: string) {
    this.auth = auth
    if (auth) sessionStorage.setItem('papi', auth)
    else sessionStorage.removeItem('papi')
  }

  get client () {
    return apis.client(this.api)
  }

  async mounted () {
    if (!this.$route.params.name) this.$router.replace({ name: 'page', params: { name: 'Introduction' } })
    await apis.client(this.api).fetchSchema()
    this.loading = false
  }

  codeToggle (): void {
    this.codeCols = this.codeCols === 3 ? 4 : 3
  }

  tryField (field: Field): void {
    this.field = field
  }
}
