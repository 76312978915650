import { GraphQLOutputType, GraphQLInputType, GraphQLArgument } from 'graphql'
import { Field, FieldType, GQLField, Arg } from '@/types/graphql'
import { GraphQLReadableReturnType } from '@/lib/readableReturnType'

export const returnType = (field: Field): FieldType => {
  return ('ofType' in field.type) ? field.type.ofType : field.type
}

export const fetchFields = (fieldType: FieldType): Field[] | Arg[] => {
  return Object.keys(fieldType._fields || {}).map(fieldName => transformType(fieldType._fields[fieldName]))
}

export const readableType = (type: GraphQLOutputType | GraphQLInputType): FieldType => {
  let fieldType = new GraphQLReadableReturnType(type).output
  if (fieldType._fields) fieldType = { ...fieldType, fields: fetchFields(fieldType) as Field[] }
  return fieldType
}

export function transformType<T>(instance: T): T extends GQLField ? Field : Arg
// eslint-disable-next-line no-redeclare
export function transformType (instance: GQLField | GraphQLArgument): Field | Arg {
  if ('args' in instance) {
    const args = instance.args.map(arg => transformType(arg)).sort((a, b) => a.name.localeCompare(b.name))
    return { ...instance, type: readableType(instance.type), args }
  } else {
    return { ...instance, type: readableType(instance.type) }
  }
}

export const typeValue = (field: Pick<Field, 'type'> | Pick<Arg, 'type'>): string => {
  if (field.type.name === 'collection') return (field.type.value as FieldType).value as string
  return field.type.value as string
}
