



















import { Component, Vue, Prop } from 'vue-property-decorator'
import { BvTableFieldArray } from 'bootstrap-vue'
import { FieldType } from '@/types/graphql'
import TypeTooltip from '@/components/graphql/shared/TypeTooltip.vue'
import FieldName from '@/components/graphql/shared/FieldName.vue'
import FieldArg from '@/components/graphql/shared/FieldArg.vue'

@Component({ components: { TypeTooltip, FieldName, FieldArg } })
export default class Type extends Vue {
  @Prop({ required: true }) fieldType!: FieldType
  @Prop({ required: true }) api!: string

  get fields (): BvTableFieldArray {
    return ['name', 'description', 'arguments', { key: 'null', class: 'text-center nullable' }]
  }
}
