




















import { Component, Vue, Prop } from 'vue-property-decorator'
import apis from '@/lib/api_repository'
import { GraphQLEnumType } from 'graphql'

@Component
export default class Enum extends Vue {
  @Prop({ required: true }) name!: string;
  @Prop({ required: true }) api!: string;

  get client () {
    return apis.client(this.api)
  }

  get instance (): GraphQLEnumType {
    return this.client.ready && this.client.getType(this.name) as GraphQLEnumType
  }

  get items () {
    if (!this.instance) return []

    return this.instance.getValues()
  }

  get fields () {
    return ['name', 'description']
  }
}
