var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"root-field p-4"},[_c('b-button',{staticClass:"try-btn pr-0",attrs:{"variant":"none"},on:{"click":_vm.tryField}},[_vm._v("Try Me"),_c('b-icon-chevron-right')],1),_c('b-row',[_c('b-col',{staticClass:"title"},[_c('h1',[_vm._v(_vm._s(_vm.rootField.name)),(_vm.rootField.isDeprecated)?_c('b-badge',{staticClass:"deprecated-badge ml-2",attrs:{"variant":"warning"}},[_vm._v("Deprecated")]):_vm._e(),_c('b-badge',{staticClass:"field-type"},[_vm._v(_vm._s(_vm.type))])],1)])],1),_c('div',{staticClass:"my-3 text-muted"},[_c('div',{staticClass:"description"},[_vm._v(_vm._s(_vm.rootField.description))]),(_vm.rootField.isDeprecated && _vm.rootField.deprecationReason.length)?_c('div',{staticClass:"deprecated mt-1"},[_vm._v("Deprecated: "+_vm._s(_vm.rootField.deprecationReason))]):_vm._e()]),_c('h5',[_vm._v("Arguments")]),_c('b-table',{attrs:{"items":_vm.rootField.args,"fields":_vm.fields,"small":""},scopedSlots:_vm._u([{key:"cell(attribute)",fn:function(ref){
var item = ref.item;
return [_c('FieldName',{attrs:{"field":item}})]}},{key:"cell(description)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-muted small"},[_vm._v(_vm._s(item.description))])]}},{key:"cell(nestedAttrs)",fn:function(ref){
var item = ref.item;
return _vm._l((item.type.args),function(arg,i){return _c('FieldArg',{key:i,staticClass:"small",class:{'mt-2': i},attrs:{"arg":arg}})})}},{key:"cell(default)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-muted small"},[_vm._v(_vm._s(_vm.getDefaultValue(item)))])]}},{key:"cell(required)",fn:function(ref){
var item = ref.item;
return [(item.type.nullable)?_c('b-icon-x'):_c('b-icon-check')]}}])}),_c('h5',{staticClass:"mt-5"},[_vm._v("Return Type"),_c('b-badge',{staticClass:"float-right",attrs:{"to":{ name: 'type', params: { api: _vm.api, type: 'type', name: _vm.returnType.value } }}},[_vm._v(_vm._s(_vm.returnType.value))])],1),_c('TypeTable',{attrs:{"field-type":_vm.returnType,"api":_vm.api}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }