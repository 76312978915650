




































import { Component, Vue, Prop } from 'vue-property-decorator'
import { BvTableFieldArray } from 'bootstrap-vue'
import { Arg, Field, FieldType, GQLField } from '@/types/graphql'
import { transformType, returnType } from '@/lib/typeConverter'
import apis from '@/lib/api_repository'
import FieldName from '@/components/graphql/shared/FieldName.vue'
import FieldArg from '@/components/graphql/shared/FieldArg.vue'
import TypeTable from '@/components/graphql/shared/TypeTable.vue'

@Component({ components: { FieldName, FieldArg, TypeTable } })
export default class RootField extends Vue {
  @Prop({ required: true }) name!: string
  @Prop({ required: true }) type!: 'query' | 'mutation'
  @Prop({ required: true }) api!: string

  get fields (): BvTableFieldArray {
    return [
      'attribute',
      'description',
      'nestedAttrs',
      { key: 'default', class: 'text-center' },
      { key: 'required', class: 'text-center' }
    ]
  }

  get rootField (): Field {
    return this.client.ready && transformType(this.getRootField())
  }

  get returnType (): FieldType {
    return returnType(this.rootField)
  }

  private get client () {
    return apis.client(this.api)
  }

  getDefaultValue (arg: Arg): any {
    if (arg.defaultValue === undefined) return

    return arg.type.value === 'String' ? `"${arg.defaultValue}"` : arg.defaultValue
  }

  tryField (): void {
    this.$emit('try-field', this.rootField)
  }

  private getRootField (): GQLField {
    return this.type === 'query' ? this.client.getQuery(this.name) : this.client.getMutation(this.name)
  }
}
